import React from "react";
import "./Plans.css";
import { plansData } from "../../data/plansData.js";
import whiteTick from "../../assets/whiteTick.png";
import { Link } from "react-scroll";
import { motion } from "framer-motion";
const Plans = () => {
  const transition = { type: "tween", duration: "1" };
  return (
    <div className="plans-container" id="Plans">
      <div className="blur plans-blur-1"></div>
      <div className="blur plans-blur-2"></div>
      <div className="programs-header" style={{ gap: "2rem" }}>
        <span className="stroke-text">READY TO START</span>
        <span>YOUR JOURNEY</span>
        <span className="stroke-text">NOW WITH US</span>
      </div>
      {/*Plans card */}

      <motion.div
        initial={{ opacity: 0, y: 100 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ transition }}
        className="plans"
      >
        {plansData.map((plan, i) => (
          <div className="plan" key={i}>
            {plan.icon}
            <span>{plan.name}</span>
            <span>$ {plan.price}</span>
            <div className="features">
              {plan.features.map((feature, i) => (
                <div className="feature">
                  <img src={whiteTick} alt="" />
                  <span key={i}>{feature}</span>
                </div>
              ))}
            </div>
            <div>
              <span>See more benifits -></span>
            </div>
            <div>
              <Link to="Join" span={true} smooth={true}>
                <button className="btn">Join Now</button>
              </Link>
            </div>
          </div>
        ))}
      </motion.div>
    </div>
  );
};

export default Plans;
