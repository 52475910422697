import React from "react";
import "./Footer.css";
import Github from "../../assets/github.png";
import Instagram from "../../assets/instagram.png";
import LinkedIn from "../../assets/linkedin.png";
import Logo from "../../assets/logo.png";
import { ExternalLink } from "react-external-link";
import { Link } from "react-scroll";
const Footer = () => {
  return (
    <div className="Footer-container">
      <hr />
      <div className="footer">
        <div className="social-links">
          <ExternalLink href="https://github.com/ankush-mdl">
            <img className="link" src={Github} alt="" />
          </ExternalLink>
          <ExternalLink href="https://www.linkedin.com/in/ankush-mdl/">
            <img className="link" src={LinkedIn} alt="" />
          </ExternalLink>
          <ExternalLink href="https://www.instagram.com/obscure__me/">
            <img className="link" src={Instagram} alt="" />
          </ExternalLink>
        </div>
        <div className="logo-f">
          <Link to="Home" span={true} smooth={true}>
            <img className="logo" src={Logo} alt="" />
          </Link>
        </div>
      </div>
      <div className="blur footer-blur-1"></div>
      <div className="blur footer-blur-2"></div>
    </div>
  );
};

export default Footer;
