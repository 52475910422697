import React from "react";
import Header from "../Header/Header";
import hero_image from "../../assets/hero_image.png";
import hero_image_back from "../../assets/hero_image_back.png";
import Heart from "../../assets/heart.png";
import Calories from "../../assets/calories.png";
import NumberCounter from "number-counter";
import { motion } from "framer-motion";
import { Link } from "react-scroll";
import "./Hero.css";
const Hero = () => {
  const transition = { type: "tween", duration: "1" };
  return (
    <div className="hero" id="Home">
      <div className="blur hero-blur"></div>
      <div className="left-h">
        <Header />
        {/*the best ad*/}
        <div className="the-best-ad">
          <motion.div
            initial={{ left: "238px" }}
            whileInView={{ left: "8px" }}
            transition={{ ...transition, type: "tween" }}
          ></motion.div>
          <span>the best fitness club in the town</span>
        </div>
        {/*hero heading*/}
        <div className="hero-text">
          <div>
            <span className="stroke-text">Shape </span>
            <span>Your</span>
          </div>

          <div>
            <span>Ideal Body</span>
          </div>
          <div>
            <span>
              In here we help you to shape and build your ideal body and live up
              to fullest
            </span>
          </div>
        </div>
        {/*figures*/}

        <div className="figures">
          <div>
            <span>
              <NumberCounter end={140} start={100} delay="5" preFix="+" />
            </span>
            <span>expert coaches</span>
          </div>
          <div>
            <span>
              <NumberCounter end={927} start={800} delay="5" preFix="+" />
            </span>
            <span>members joined</span>
          </div>
          <div>
            <span>
              <NumberCounter end={50} start={30} delay="5" preFix="+" />
            </span>
            <span>fitness programs</span>
          </div>
        </div>

        {/* {hero buttons} */}

        <div className="hero-buttons">
          <Link to="Plans" span={true} smooth={true}>
            <buttons className="btn get-started">Get Started</buttons>
          </Link>
          <Link to="Programs" span={true} smooth={true}>
            <buttons className="btn learn-more">Learn More</buttons>
          </Link>
        </div>
      </div>
      <div className="right-h">
        <Link to="Join" span={true} smooth={true}>
          <motion.button
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ transition }}
            className="btn button-r-h"
          >
            Join Now
          </motion.button>
        </Link>
        <motion.div
          transition={transition}
          initial={{ right: "-1rem" }}
          whileInView={{ right: "4rem" }}
          className="heart-rate"
        >
          <img src={Heart} alt="" />
          <span>Heart Rate</span>
          <span>116 bpm</span>
        </motion.div>
        {/* Hero images */}
        <img src={hero_image} alt="" className="hero-image" />
        <motion.img
          transition={transition}
          initial={{ right: "11rem" }}
          whileInView={{ right: "25rem" }}
          src={hero_image_back}
          alt=""
          className="hero-image-back"
        />

        {/* calories */}
        <motion.div
          transition={transition}
          initial={{ right: "37rem" }}
          whileInView={{ right: "28rem" }}
          className="calories"
        >
          <img src={Calories} alt="" />
          <div>
            <span>Calories Burned</span>
            <span>220 kcal</span>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default Hero;
